import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import VuePrism from 'vue-prism'
Vue.use(VuePrism)

import 'prismjs/components/prism-json'
import 'prismjs/themes/prism.css'
